import { graphql, Link, PageProps } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import * as React from "react";

import { FeedIcon } from "@/components/icons/FeedIcon";
import { Layout } from "@/components/Layout";
import { PostListItem } from "@/components/PostListItem";

const PostPage: React.FC<PageProps<Queries.PostListQuery>> = ({ data }) => {
  return (
    <Layout>
      <GatsbySeo title="Post" />
      <div className="prose pt-12 pb-8">
        <h1 className="md:text-6xl">Post</h1>
        <Link to="/rss.xml">
          <span className="text-2xl text-gray-500 transition-all hover:text-gray-700 hover:underline">
            <FeedIcon />
          </span>
        </Link>
      </div>
      <ul className="list-none">
        {data.allContentfulPost.nodes.map((props) => (
          <PostListItem {...props} />
        ))}
      </ul>
    </Layout>
  );
};

export const query = graphql`
  query PostList {
    allContentfulPost(limit: 10) {
      nodes {
        ...PostListItemProps
      }
    }
  }
`;

export default PostPage;
